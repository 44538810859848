@tailwind base;
@tailwind components;
@tailwind utilities;

.rdrDefinedRangesWrapper {
  display: none;
}

@layer components {
  .gray-button {
    @apply bg-white text-gray-500 px-4 py-2 text-xs font-semibold rounded-xl border cursor-pointer hover:shadow-sm active:scale-95
    active:bg-gray-50 transition transform duration-100 ease-out flex items-center hover:bg-gray-50 hover:scale-105;
  }

  .max-width-content {
    @apply max-w-7xl mx-auto px-5 sm:px-20;
  }
  .violet-content {
    @apply bg-violet-50 mx-1 px-5 sm:px-8 mt-5 items-center justify-center text-center text-sm;
  }

  .carrousel-container { 
    @apply flex space-x-5 overflow-scroll scrollbar-hide p-3 -ml-3;
  }

  .purple-button {
    @apply py-2 px-4 bg-violet-700 text-white font-semibold shadow-sm rounded-lg hover:scale-[1.02] hover:shadow-md items-center;
  }

  .purple-white {
    @apply py-2 px-4 bg-white text-violet-700 border-[1px] border-violet-700 font-semibold shadow-sm rounded-lg hover:scale-105 hover:shadow-md;
  }

  .footer-column {
    @apply space-y-4 items-center;
  }

  .titles {
    @apply text-4xl font-bold pb-2 text-center;
  }

  .subtitles {
    @apply text-2xl font-bold pb-2;
  }

  .guide-titles {
    @apply text-violet-700 font-bold text-2xl/[40px] text-left pb-3;
  }

  .guide-subtitles {
    @apply text-violet-700 font-bold text-lg/[40px] text-left;
  }

  .guide-text {
    @apply text-sm/[25px] pb-5 text-justify;
  }

  .guide-links {
    @apply text-base/[30px] font-semibold;
  }

  .purple-guide-tab {
    @apply bg-violet-600 text-white px-2 py-4 rounded-t-2xl cursor-pointer;
  }

  .white-guide-tab {
    @apply bg-gray-50 text-black px-2 py-4 rounded-t-2xl cursor-pointer;
  }

  .shimmer_skeleton {
    @apply animate-pulse before:absolute before:inset-0 before:-translate-x-full before:animate-[shimmer_2s_infinite] before:bg-gradient-to-r before:from-transparent before:via-white/60 before:to-transparent;
  }
}
